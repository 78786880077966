import React from 'react'
import { Text } from 'theme-ui'
import { graphql } from 'gatsby'
import Seo from '@solid-ui-components/Seo'
import Divider from '@solid-ui-components/Divider'
import { normalizeBlockContentNodes } from '@blocks-helpers'
import KtLayout from '@solid-ui-blocks/KtLayout/KtLayout'
import kt404 from '../homepage/saas-v2/assets/kt-404.png'
import CanliDestek from '@solid-ui-blocks/CanliDestek/CanliDestek'



const NotFoundPage = props => {
  const { allBlockContent } = props.data
  const content = normalizeBlockContentNodes(allBlockContent?.nodes)

  return (
    // <Layout {...props}>
    //   <Seo title='Home' />
    //   <Header content={content['header']} />
    //   <Divider space='5' />
    //   <Divider space='5' />
    //   {/* <Message content={content['404']} reverse /> */}
    // </Layout>
    <KtLayout prp={props} cnt={content} lightmi={false} >
      <Seo title='Sayfa Bulunamadı ' />
      <Divider space='5' />
      <div className='dv-l'>
        <Divider space="3" />
        <img src={kt404} alt="online box die cutting 404 page" style={{ width: "400px", objectFit: "contain" }} />
        <Divider space="2" />
      </div>
      <Text sx={{ textAlign: "center" }} variant="h5" color="alpha">  Sayfa Bulunmadı  </Text>
      <Divider space='5' />
      <CanliDestek></CanliDestek>
    </KtLayout>
  )
}

export const query = graphql`
  query misc404BlockContent {
    allBlockContent(filter: { page: { in: ["innerpage/404", "shared"] } }) {
      nodes {
        ...BlockContent
      }
    }
  }
`

export default NotFoundPage
